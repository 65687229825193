import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { IconQuestionmarkCircleLine } from '@daangn/react-monochrome-icon';

import IconWrapper from '@/components/common/Icons';
import { useUserConfig } from '@/contexts/UserConfig';
import { openLink } from '@/utils/link';

import MoreMenu from './MoreMenu';

export const ChallengeDetailAppbarRightButton = ({
  challengeDetail,
  groupId,
}: {
  challengeDetail: ChallengeDetailPresentation;
  groupId: string;
}) => {
  const { userConfig } = useUserConfig();

  const showQuestionMark = userConfig.userId !== challengeDetail.author.id;

  const handleOpenSurvey = () => {
    const surveyLink = 'https://www.daangn.com/wv/faqs/16948';
    openLink(surveyLink, false);
  };

  if (showQuestionMark) {
    return (
      <IconWrapper>
        <IconQuestionmarkCircleLine size={24} onClick={handleOpenSurvey} />
      </IconWrapper>
    );
  }

  return <MoreMenu challengeDetail={challengeDetail} groupId={groupId} />;
};
